@font-face {
    font-family: "StolzlBook";
    src: url("StolzlBook/StolzlBook.eot");
    src: url("StolzlBook/StolzlBook.eot?#iefix")format("embedded-opentype"),
    url("StolzlBook/StolzlBook.woff") format("woff"),
    url("StolzlBook/StolzlBook.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}

.font_stolzl_book
{
    font-family:StolzlBook,sans-serif !important;
}